import React from 'react'
import Layout from '../components/layout2'
import Container from '../components/container'
import Vbox from '../components/vbox'
import Top from '../components/top'
import Summary from '../components/summary'
import Button from '../components/button'
import Img from '../components/img'

class RootIndex extends React.Component {
  render() {
 
    return <Layout>
      <Container>
        <Vbox widegap>
          <Top 
            title="Bibliotek Forum"
            body="Det nya biblioteket och den centrala samlingsplatsen för invånarna i Jordbro.."
            image="/forum.png"
          />
          <div>
            <h2 className="h2">1 - bakgrund & översikt: </h2>
            <Container narrow>
              <h3 className='h3'>Jordbro</h3>
              <p className="body">Ett nytt centrum planeras i anslutning till pendeltågsstationen i Jordbro, beläget i Haninge kommun. Projektet inkluderar en ny bussterminal, ett torg, cirka 270 nya bostäder, en förskola och ett bibliotek.</p>
              <p className="body">Målet är att bevara Jordbros småstadskaraktär, med fokus på gångavstånd till pendeltågsstationen och en bebyggelse som harmonierar med det befintliga Jordbro centrum. Området präglas idag av en blandning av flerbostadshus och småhus, främst från 60- och 70-talet.</p>
              <p className="body">Jordbro är hem för en mångfald av människor från olika kulturer och delar av världen, vilket berikar samhället. En av de unika aspekterna av Jordbro är dess livliga föreningsliv, som spelar en central roll för engagemanget i tätorten.</p>
            </Container>
          </div>
          <Summary>
            <h4 className="h4">Roll</h4>
            <p className="small">Designer / Arkitekt</p>
            <h4 className="h4">Uppdrag</h4>
            <p className="small">Mitt uppdrag som arkitektstudent var att ta fram ett förslag på det nya biblioteket i Jordbro utifrån en given tomtyta och ett program (vilka och hur stora funktionsytor som ska finnas med - krav från beställare).</p>
            <h4 className="h4">Tidsram</h4>
            <p className="small">11 veckor (2020)</p>
            <h4 className="h4">Varför ett arkitektprojekt?</h4>
            <p className="small">UX är ju inte bara något som relaterar till den digitala världen utan också vår fysiska värld.
            I detta projekt jobbade jag med många liknande användarcentrerade metoder och designprocesser som för de digitala UX-projekten, för att tillslut landa i ett förslag.</p>
  <h4 className="h4">Resultat</h4>
            <p className="small">Jag konceptualiserade flera idéer med syfte att skapa en naturlig mötesplats för Jordbros invånare.</p>
            <p className="small">Detta resulterade i ett centralt bibliotek/forum för kunskapsutbyte där den starka föreningskulturen i området får ta plats och vara i huvudfokus snarare än böcker. En byggnad vars övergripande sturktur är formad efter de tydligaste rörelsemönstrena över torget för att bjuda in och uppmuntra till spontanbesök.</p>
          </Summary>
          <div>
            <h2 className="h2">2 - resultat:</h2>
            <Container narrow>
              <h3 className='h3'>Planritningar och visualiseringar</h3>
              <h4 className='h4'>Bibliotek forum</h4>
              <p className="body">Bibliotek Forum är en engagerande samlingsplats för nya idéer i Jordbro.</p>
              <p className="body">I en allt mer digitaliserad värld där den fysiska boken får mindre plats så förändras definitionen av vad ett bibliotek är. Med internet som primär kunskapskälla så uppstår också “filterbubblor”, ett sätt att spräcka dem är att träffa andra människor, få utbyte och bli inspirerad. Men för att kunna bli inspirerad så måste man bli exponerad.</p>
              <Img src="/forum1.png" caption="Visualiseringar" />
              <Img src="/forum2.png" caption="Planritningar" />
              <h4 className='h4'>Utformning</h4>
              <p className="body">Bibliotek forum är utformad efter två huvudstråk över torget som översatts till två ortogonala passager genom bibliotekets entrévåning där lokaler kopplade till föreningsverksamheten får ta plats. Vidare upp i huset blir dessa passager till hålrum och broar som ger sikt ända upp till himmelen.</p>
              <p className="body">Denna form tillgängliggör och exponerar det engagemang och aktiviteter som finns i Jordbro.</p>
            </Container>
          </div>
          <div>
            <h2 className="h2">3 - processen:</h2>
            <Container narrow>
              <h3 className='h3'>Processmodell</h3>
              <Img narrow src="/forum3.png" />
            </Container>
          </div>
          <div>
            <Container narrow>
              <h3 className='h3'>Research</h3>
              <Img narrow src="/forum4.png" caption="Viktiga stråk och flöden i Jordbro centrum" />
              <h4 className='h4'>Platsanalys</h4>
              <p className="body">Jag gjorde återkommande besök till platsen för att få en förståelse för hur området är uppbyggt och strukturerat. Frågor som jag arbetade kring för insamling av data: Hur ser flödet ut? Vilka är huvudstråken? Vad finns för typologi? Vad finns det för grönområden? Vilka är de viktigaste noderna? Vad är viktigt för de boende i Jordbro?</p>
              <h4 className='h4'>Insikter och scope</h4>
              <p className="body">Svaren frågorna jag ställde i platsanalysen hjälpte mig att få styrfart i designprocessen genom att fatta beslut om vad jag ville arbeta vidare med. I det här fallet förankrade jag designen i stråken och flödena som fick en avgörande roll för den övergripande utformningen av byggnaden, men också insikter från intervju med den boende som lyfte Jordbro som en plats där föreningslivet och mötet mellan människor spelade en viktig roll.</p>
              <h4 className='h4'>Programanalys</h4>
              <p className="body">I och med att det var ett stort program med många funktioner som skulle in på en begränsad yta så behövde det sorteras i de olika delarna för att kunna fatta rimliga designbeslut. Jag jobbade med många olika diagram i både plan och volym för att bland annat förstå de rumsliga sambanden, relationen mellan användare och funktion, privata och offentliga ytor, vilka funktioner utnyttjas av flest användare etc. Detta hjälpte mig att fatta beslut om vilka funktioner som skulle placeras vart i byggnaden.</p>
              <Img narrow src="/forum5.png" caption="Programstudie i volym" />
              <Img narrow src="/forum6.png" caption="Programstudie i volym" />
              <h4 className='h4'>Insikter och övergripande designbeslut</h4>
              <p className="body">Då föreningslivet och mötet mellan människor spelade en stor roll i Jordbro valde jag att sammankoppla de aktiviteter och funktioner som skulle främja dessa aktiviteter. Så som föreläsningssal, workshoprum, utställningslokal och café och placera dessa på den mest lättillgängliga och mest exponerade platsen i byggnaden.</p>
            </Container>
          </div>
          <div>
            <Container narrow>
              <h3 className='h3'>Koncept & design</h3>
              <Img narrow src="/forum7.png" caption="Konceptskisser" />
              <Img narrow src="/forum8.png" caption="Konceptskisser i 3D" />
              <h4 className='h4'>Insikter</h4>
              <p className="body">Skisserna illustrerar tester av konceptet kring flöden genom byggnaden, där förslag C blev den variant jag valde att utveckla vidare.</p>
            </Container>
          </div>
          <div>
            <h2 className="h2">4 - summering:</h2>
            <Container narrow>
              <h3 className='h3'>Insikter & lärdomar</h3>
              <h4 className='h4'>Designprocessen</h4>
              <p className="body">God design är förankrad och byggd på insikter. Att sätta upp ramar som hjälper kreativiteten ger styrfart</p>
              <h4 className='h4'>Våga vara kritisk</h4>
              <p className="body">Att definiera om vad ett bibliotek kan vara hjälpte mig att se nya möjligheter. Vad var behovet? I detta fall handlade biblioteket inte om en plats att kunna låna böcker utan mer om mötet mellan människor och olika aktiviteter. Det var viktigt att kunna definiera om begreppet för att kunna lösa problemet på rätt sätt för de tänkta målgrupperna. </p>
              <h4 className='h4'>Iteration</h4>
              <p className="body">Finns ingen genväg till ett bra slutresultat.</p>
            </Container>
          </div>
          
          <div>
            <h2 className="h2">fler projekt:</h2>
            <div className='more-wrapper'>
              <div class="more">
                <img src='/jhl.png' />
                <h3 className='h3'>Jagharläst.se</h3>
                <Button to="/jagharlast">Utforska hela projektet {"->"}</Button>
              </div>
              <div class="more">
                <img src='/roder.png' />
                <h3 className='h3'>Internverktyg för Roder Innovation</h3>
                <Button to="/roder">Utforska hela projektet {"->"}</Button>
              </div>
            </div>
          </div>
        </Vbox>
      </Container>
    </Layout>
    
    
  }
}

export default RootIndex